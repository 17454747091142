<script>
import IconButton from '../../../components/Common/IconButton.vue';

export default {
  components: { IconButton },
  name: 'recurring_task_edit',

  props: {
    'userData': Object
  },

  data() { return {
    selected: 0,
    categories: ['daily','weekly','monthly','quarterly','anual'],
    tasks: [],
    inputValue: "",
  }},

  computed: {
    selectedCategory() {
      return this.categories[this.selected];
    },

    taskCount() {
      return this.tasks.length;
    }
  },
  
  created() {
    this.RequestTasks();
  },

  methods: {
    OnChangeCategory()
    {
      this.RequestTasks();
    },

    RequestTasks()
    {
      this.$api.get({path: `/users/${this.userData.id}/recurring-tasks/${this.selectedCategory}`}, this.OnRequestResponse);
    },

    OnRequestResponse({response})
    {
      const { task_list } = response.data;
      this.tasks = task_list;
    },

    CreateTask()
    {
      const data = {
        task_data: this.inputValue.trim()
      };

      this.$api.post({ path: `/users/${this.userData.id}/recurring-tasks/${this.selectedCategory}`, data }, this.OnPostResponse);
    },

    OnPostResponse({response})
    {
      if(response.status != "success") {
        this.$msg.push("Task-ul nu a putut fi creat.", "error")
        return;
      }

      const { new_task } = response.data;

      this.tasks.push(new_task);
      this.inputValue = "";
      this.SrollListToBottom();
    },
    
    RemoveTask(id)
    {
      this.$api.delete({ path: `/users/${this.userData.id}/recurring-tasks/${id}` }, this.OnDeleteResponse);
    },

    OnDeleteResponse({response})
    {
      if(response.status != "success") {
        this.$msg.push("Task-ul nu a putut fi sters.", "error")
        return;
      }

      for(var index in this.tasks) {
        const task = this.tasks[index];
        if(task.id != response.data.task_id) {
          continue;
        }

        this.tasks.splice(index, 1);
        break;
      }
    },

    GetCategoryTitle(categoryName)
    {
      return this.$lan(`task_category_title_${categoryName.toLowerCase()}`);
    },

    SrollListToBottom()
    {
      const taskListRef = this.$refs.taskList;
      if(!taskListRef) {
        return;
      }

      taskListRef.scroll({ top: taskListRef.scrollHeight, behavior: 'smooth' });
    }
  }
}
</script>


<template>
  <div class="recurring-tasks">

    <form @submit.prevent="CreateTask">
      <h3>Task-uri Periodice</h3>

      <input-box placeholder="Detalii" v-model:value="inputValue" required>
        <icon-button type="submit">
          add
        </icon-button>
      </input-box>
      
      <select name="filter" class="input type-pick" @change="OnChangeCategory()" v-model="selected">
        <option v-for="(categoryName, i) in categories" :key="i" :value="i">
          {{GetCategoryTitle(categoryName)}}
        </option>
      </select>
    </form>

    <div class="task-row" v-if="taskCount == 0">
      {{ $lan("no_results") }}
    </div>

    <div class="task-list" ref="taskList" v-else>
      <div v-for="(task, i) in tasks" :key="i" class="task-row">
        <span>{{task.text}}</span>

        <icon-button class="no-bg red" @click="RemoveTask(task.id)">
          close
        </icon-button>
      </div>
    </div>

  </div>
</template>

<style scoped>
.recurring-tasks {
  width: 30%;
}

.recurring-tasks form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: var(--dark-box-color);
  border-radius: 20px;
}

.recurring-tasks form .input-box {
  width: 100%;
}

.task-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  max-height: 450px;
}

.task-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--dark-box-color);
  border-radius: 10px;
}

.task-row .icon-button {
  font-size: small;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1000px ) {
  .recurring-tasks {
    width: 100%;
  }
}
</style>

